<template>
    <div class="app-container">
        <el-form :model="query" ref="queryForm" :inline="true" label-width="68px">
            <el-form-item label="站点名称" prop="name">
                <el-input v-model="query.name" placeholder="请输入站点名称" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="手机号" prop="phone" >
                <el-input v-model="query.phone" placeholder="请输入手机号" clearable size="small"
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item label="申请状态" prop="status" >
                <el-select v-model="query.status" placeholder="请选择状态" clearable size="small">
                    <el-option v-for="dict in dictType" :key="dict.value" :label="dict.label" :value="dict.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" size="mini" @click="reset">重置</el-button>
            </el-form-item>
        </el-form>

        <el-table v-loading="loading" :data="list" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" align="center" />
            <el-table-column label="站点名称" align="center" prop="name" />
            <el-table-column label="省份" align="center" prop="province" />
            <el-table-column label="城市" align="center" prop="city" />
            <el-table-column label="县（区）" align="center" prop="county" />
            <el-table-column label="详细地址" align="center" prop="address" />
            <el-table-column label="经纬度" align="center" prop="longitude" width="165">
                <template #default="scope">
                    <span style="color: #409EFF">{{ scope.row.longitude }}</span>，<span style="color: #409EFF">{{ scope.row.latitude }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请人" align="center" prop="nickname" />
            <el-table-column label="头像" align="center" prop="headImg">
                <template v-slot="slot">
                    <div v-if="slot.row.headImg == null || slot.row.headImg == ''">未上传头像</div>
                    <img :src="slot.row.headImg" width="80px" alt="image error" v-else>
                </template>
            </el-table-column>
            <el-table-column label="手机号" align="center" prop="phone" />
            <el-table-column label="申请时间" align="center" prop="createTime" />
            <el-table-column label="申请备注" align="center" prop="remark" />
            <el-table-column label="审核日期" align="center" prop="auditDate" />
            <el-table-column label="申请状态" align="center" prop="status" >
                <template #default="scope">
                    <el-tag :type="statusDict[scope.row.status].color">{{ statusDict[scope.row.status].label }}</el-tag>
                </template>
            </el-table-column>
            

            <el-table-column label="操作" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button v-show="scope.row.status == '0'" size="mini"
                        type="text" icon="el-icon-edit" @click="handleAudit(scope.row, 1)"
                        v-hasPermi="['ums:site:audit']">同意</el-button>
                    <el-button v-show="scope.row.status == '0'" size="mini"
                        type="text" icon="el-icon-delete" @click="handleAudit(scope.row, -1)"
                        v-hasPermi="['ums:site:audit']">拒绝</el-button>

                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="query.current" :limit.sync="query.size"
            @pagination="getList" />
    </div>
</template>

<script>
import api from '@/api/commonApi'

export default {
    name: 'SiteAudit',
    data() {
        return {
            // 列表
            list: [],
            // 类型
            dictType: [
                { value: -2, label: '禁用' },
                { value: -1, label: '拒绝' },
                { value: 0, label: '待审批' },
                { value: 1, label: '通过' },
            ],
            // 总条数
            total: 0,
            // 遮罩层
            loading: true,
            // 查询
            query: {
                current: 1,
                size: 10,
                status: null,
                name: '',
                phone: ''
            },
            // 选择
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 状态
            statusDict: {
                '-2': { color: 'danger', label: '禁用' },
                '-1': { color: 'danger', label: '拒绝' },
                '0': { color: 'warning', label: '待审批' },
                '1': { color: 'success', label: '通过' },
            }
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 获取列表数据
        getList() {
            this.loading = true
            api.siteList(this.query).then(res => {
                this.total = res.total
                this.list = res.records
                this.loading = false
            })
        },
        // 搜索
        handleQuery() {
            this.getList()
        },
        // 重置
        reset() {
            this.query = {
                current: 1,
                size: 10,
                status: null,
                name: '',
                phone: ''
            }
            this.getList()
        },
        /** 多选框选中数据 */
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length !== 1
            this.multiple = !selection.length
        },
        // 审批
        handleAudit(data, type) {
            this.$confirm('是否' + (type == 1?'通过':'拒绝') + data.name + '此站点？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.siteAudit({
                    id: data.id,
                    status, type
                }).then(res => {
                    this.$message.success("审批成功")
                    this.getList()
                }).catch(() => {})
            })
        }
    }
}

</script>